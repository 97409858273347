<template>
	<div class="analytics content-padding">
		<ContentContainer :order="{ body: 1, sidebar: 2 }">
			<template slot="body">
				<h2>{{labels.chart}}</h2>
				<BaseCard class="p-0 p-sm-2 p-md-4" margin="0 0 25px">
					<LineChart/>
				</BaseCard>
				<p>{{labels.first_some_text}}</p>
			</template>
			<template slot="sidebar">
				<h2>{{labels.report}}</h2>
				<LabelButtonCard :list="LabelButtonList" class="mb-4"/>
				<p>{{labels.second_some_text}}</p>
			</template>
		</ContentContainer>
	</div>
</template>

<script>
import ContentContainer from "@/components/Containers/ContentContainer"
import LineChart from "@/components/Chart/LineChart"
import LabelButtonCard from '@/components/Cards/LabelButtonCard'
import BaseCard from "@/components/Cards/BaseCard"

export default {
	name: 'Analytics',
	components: {
		ContentContainer,
		LineChart,
		LabelButtonCard,
		BaseCard,
	},
	data() {
		return {
			labels: {
				chart: 'Chart',
				report: 'Report',
				first_some_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam rutrum molestie nulla eget tristique. Praesent porta at urna sit amet fringilla',
				second_some_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
			},
			LabelButtonList: [
				{
					label: 'Month',
					actions: [
						{ type: 'button', label: 'Download', variant: 'btn-navy', action:()=>{} },
					],
				},
				{
					label: 'Month',
					actions: [
						{ type: 'button', label: 'Download', variant: 'btn-navy', action:()=>{} },
					],
				},
				{
					label: 'Month',
					actions: [
						{ type: 'button', label: 'Download', variant: 'btn-navy', action:()=>{} },
					],
				},
				{
					label: 'Month',
					actions: [
						{ type: 'button', label: 'Download', variant: 'btn-navy', action:()=>{} },
					],
				},
				{
					label: 'Month',
					actions: [
						{ type: 'button', label: 'Download', variant: 'btn-navy', action:()=>{} },
					],
				},
			]
		}
	}
}
</script>

<style lang='scss' scoped>

</style>